import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDemoChartAreaStats, LazyDemoChartBarSocialChannels, LazyDemoCompanyOverview, LazyDemoSearchCompact, LazyDemoTeamListCompact, LazyDemoTodoListCompact, LazyTairoCollapseNavigationCollapseLinks, LazyTeampoolAccountMenu, LazyTeampoolAutocomplete, LazyTeampoolAutocompleteInserate, LazyTeampoolAvatar, LazyTeampoolAvatarGroup, LazyTeampoolBranchSelect, LazyTeampoolCollapseNavigationFooter, LazyTeampoolCollapseNavigationHeader, LazyTeampoolError, LazyTeampoolFlexTableCell, LazyTeampoolFlexTableRow, LazyTeampoolFlexTableStart, LazyTeampoolFlexTableWrapper, LazyTeampoolJobContextDropdown, LazyTeampoolLocationTag, LazyTeampoolLogo, LazyTeampoolPasswordStrength, LazyTeampoolPlatformAvatarGroup, LazyTeampoolRTE, LazyTeampoolTag, LazyTeampoolTairoToc, LazyThemeToggle, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["DemoChartAreaStats", LazyDemoChartAreaStats],
["DemoChartBarSocialChannels", LazyDemoChartBarSocialChannels],
["DemoCompanyOverview", LazyDemoCompanyOverview],
["DemoSearchCompact", LazyDemoSearchCompact],
["DemoTeamListCompact", LazyDemoTeamListCompact],
["DemoTodoListCompact", LazyDemoTodoListCompact],
["TairoCollapseNavigationCollapseLinks", LazyTairoCollapseNavigationCollapseLinks],
["TeampoolAccountMenu", LazyTeampoolAccountMenu],
["TeampoolAutocomplete", LazyTeampoolAutocomplete],
["TeampoolAutocompleteInserate", LazyTeampoolAutocompleteInserate],
["TeampoolAvatar", LazyTeampoolAvatar],
["TeampoolAvatarGroup", LazyTeampoolAvatarGroup],
["TeampoolBranchSelect", LazyTeampoolBranchSelect],
["TeampoolCollapseNavigationFooter", LazyTeampoolCollapseNavigationFooter],
["TeampoolCollapseNavigationHeader", LazyTeampoolCollapseNavigationHeader],
["TeampoolError", LazyTeampoolError],
["TeampoolFlexTableCell", LazyTeampoolFlexTableCell],
["TeampoolFlexTableRow", LazyTeampoolFlexTableRow],
["TeampoolFlexTableStart", LazyTeampoolFlexTableStart],
["TeampoolFlexTableWrapper", LazyTeampoolFlexTableWrapper],
["TeampoolJobContextDropdown", LazyTeampoolJobContextDropdown],
["TeampoolLocationTag", LazyTeampoolLocationTag],
["TeampoolLogo", LazyTeampoolLogo],
["TeampoolPasswordStrength", LazyTeampoolPasswordStrength],
["TeampoolPlatformAvatarGroup", LazyTeampoolPlatformAvatarGroup],
["TeampoolRTE", LazyTeampoolRTE],
["TeampoolTag", LazyTeampoolTag],
["TeampoolTairoToc", LazyTeampoolTairoToc],
["ThemeToggle", LazyThemeToggle],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

import { default as applicantsr1W99Vx29iMeta } from "/app/.app/pages/applicants.vue?macro=true";
import { default as global3T89wCdaC2Meta } from "/app/.app/pages/contingent/global.vue?macro=true";
import { default as jobcontextb3wPyMbp0nMeta } from "/app/.app/pages/contingent/jobcontext.vue?macro=true";
import { default as indexxNJ2T4zPkRMeta } from "/app/.app/pages/index.vue?macro=true";
import { default as index37iVrqF6gwMeta } from "/app/.app/pages/inserate/[id]/index.vue?macro=true";
import { default as indexQ7SzWBOjDEMeta } from "/app/.app/pages/inserate/add/index.vue?macro=true";
import { default as index2hWEMNyspuMeta } from "/app/.app/pages/inserate/deactivated/index.vue?macro=true";
import { default as indexbxBsV8hqmMMeta } from "/app/.app/pages/inserate/index.vue?macro=true";
import { default as indexpjTOQQUvuqMeta } from "/app/.app/pages/job-presets/[id]/index.vue?macro=true";
import { default as indexZJ7Yt2kxg2Meta } from "/app/.app/pages/job-presets/add/index.vue?macro=true";
import { default as indexTg0g3ZNjeoMeta } from "/app/.app/pages/job-presets/deactivated/index.vue?macro=true";
import { default as index8vw76RZCAZMeta } from "/app/.app/pages/job-presets/index.vue?macro=true";
import { default as logincIYgecPLM8Meta } from "/app/.app/pages/login.vue?macro=true";
import { default as platformswbhBFWbRIiMeta } from "/app/.app/pages/platforms.vue?macro=true";
import { default as change_45passwordjE4cK81Pn5Meta } from "/app/.app/pages/profile/change-password.vue?macro=true";
import { default as indexjlsJkxJZqCMeta } from "/app/.app/pages/profile/index.vue?macro=true";
import { default as statisticsktP0BtS4SDMeta } from "/app/.app/pages/statistics.vue?macro=true";
import { default as _91id_93O6ATLKfC8UMeta } from "/app/.app/pages/templates/edit/[id].vue?macro=true";
import { default as indexWjD8yLleCAMeta } from "/app/.app/pages/templates/index.vue?macro=true";
import { default as _91id_93Px7AHBhIhFMeta } from "/app/.app/pages/users/[id].vue?macro=true";
import { default as index7p2oZ4vBkVMeta } from "/app/.app/pages/users/index.vue?macro=true";
import { default as authkpJ2osrxIFMeta } from "/app/.app/modules/auth/middleware/auth.ts?macro=true";
export default [
  {
    name: applicantsr1W99Vx29iMeta?.name ?? "applicants___de",
    path: applicantsr1W99Vx29iMeta?.path ?? "/applicants",
    meta: applicantsr1W99Vx29iMeta || {},
    alias: applicantsr1W99Vx29iMeta?.alias || [],
    redirect: applicantsr1W99Vx29iMeta?.redirect,
    component: () => import("/app/.app/pages/applicants.vue").then(m => m.default || m)
  },
  {
    name: applicantsr1W99Vx29iMeta?.name ?? "applicants___en",
    path: applicantsr1W99Vx29iMeta?.path ?? "/en/applicants",
    meta: applicantsr1W99Vx29iMeta || {},
    alias: applicantsr1W99Vx29iMeta?.alias || [],
    redirect: applicantsr1W99Vx29iMeta?.redirect,
    component: () => import("/app/.app/pages/applicants.vue").then(m => m.default || m)
  },
  {
    name: global3T89wCdaC2Meta?.name ?? "contingent-global___de",
    path: global3T89wCdaC2Meta?.path ?? "/contingent/global",
    meta: global3T89wCdaC2Meta || {},
    alias: global3T89wCdaC2Meta?.alias || [],
    redirect: global3T89wCdaC2Meta?.redirect,
    component: () => import("/app/.app/pages/contingent/global.vue").then(m => m.default || m)
  },
  {
    name: global3T89wCdaC2Meta?.name ?? "contingent-global___en",
    path: global3T89wCdaC2Meta?.path ?? "/en/contingent/global",
    meta: global3T89wCdaC2Meta || {},
    alias: global3T89wCdaC2Meta?.alias || [],
    redirect: global3T89wCdaC2Meta?.redirect,
    component: () => import("/app/.app/pages/contingent/global.vue").then(m => m.default || m)
  },
  {
    name: jobcontextb3wPyMbp0nMeta?.name ?? "contingent-jobcontext___de",
    path: jobcontextb3wPyMbp0nMeta?.path ?? "/contingent/jobcontext",
    meta: jobcontextb3wPyMbp0nMeta || {},
    alias: jobcontextb3wPyMbp0nMeta?.alias || [],
    redirect: jobcontextb3wPyMbp0nMeta?.redirect,
    component: () => import("/app/.app/pages/contingent/jobcontext.vue").then(m => m.default || m)
  },
  {
    name: jobcontextb3wPyMbp0nMeta?.name ?? "contingent-jobcontext___en",
    path: jobcontextb3wPyMbp0nMeta?.path ?? "/en/contingent/jobcontext",
    meta: jobcontextb3wPyMbp0nMeta || {},
    alias: jobcontextb3wPyMbp0nMeta?.alias || [],
    redirect: jobcontextb3wPyMbp0nMeta?.redirect,
    component: () => import("/app/.app/pages/contingent/jobcontext.vue").then(m => m.default || m)
  },
  {
    name: indexxNJ2T4zPkRMeta?.name ?? "index___de",
    path: indexxNJ2T4zPkRMeta?.path ?? "/",
    meta: indexxNJ2T4zPkRMeta || {},
    alias: indexxNJ2T4zPkRMeta?.alias || [],
    redirect: indexxNJ2T4zPkRMeta?.redirect,
    component: () => import("/app/.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxNJ2T4zPkRMeta?.name ?? "index___en",
    path: indexxNJ2T4zPkRMeta?.path ?? "/en",
    meta: indexxNJ2T4zPkRMeta || {},
    alias: indexxNJ2T4zPkRMeta?.alias || [],
    redirect: indexxNJ2T4zPkRMeta?.redirect,
    component: () => import("/app/.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index37iVrqF6gwMeta?.name ?? "inserate-id___de",
    path: index37iVrqF6gwMeta?.path ?? "/inserate/:id()",
    meta: index37iVrqF6gwMeta || {},
    alias: index37iVrqF6gwMeta?.alias || [],
    redirect: index37iVrqF6gwMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index37iVrqF6gwMeta?.name ?? "inserate-id___en",
    path: index37iVrqF6gwMeta?.path ?? "/en/inserate/:id()",
    meta: index37iVrqF6gwMeta || {},
    alias: index37iVrqF6gwMeta?.alias || [],
    redirect: index37iVrqF6gwMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ7SzWBOjDEMeta?.name ?? "inserate-add___de",
    path: indexQ7SzWBOjDEMeta?.path ?? "/inserate/add",
    meta: indexQ7SzWBOjDEMeta || {},
    alias: indexQ7SzWBOjDEMeta?.alias || [],
    redirect: indexQ7SzWBOjDEMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ7SzWBOjDEMeta?.name ?? "inserate-add___en",
    path: indexQ7SzWBOjDEMeta?.path ?? "/en/inserate/add",
    meta: indexQ7SzWBOjDEMeta || {},
    alias: indexQ7SzWBOjDEMeta?.alias || [],
    redirect: indexQ7SzWBOjDEMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/add/index.vue").then(m => m.default || m)
  },
  {
    name: index2hWEMNyspuMeta?.name ?? "inserate-deactivated___de",
    path: index2hWEMNyspuMeta?.path ?? "/inserate/deactivated",
    meta: index2hWEMNyspuMeta || {},
    alias: index2hWEMNyspuMeta?.alias || [],
    redirect: index2hWEMNyspuMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/deactivated/index.vue").then(m => m.default || m)
  },
  {
    name: index2hWEMNyspuMeta?.name ?? "inserate-deactivated___en",
    path: index2hWEMNyspuMeta?.path ?? "/en/inserate/deactivated",
    meta: index2hWEMNyspuMeta || {},
    alias: index2hWEMNyspuMeta?.alias || [],
    redirect: index2hWEMNyspuMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/deactivated/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxBsV8hqmMMeta?.name ?? "inserate___de",
    path: indexbxBsV8hqmMMeta?.path ?? "/inserate",
    meta: indexbxBsV8hqmMMeta || {},
    alias: indexbxBsV8hqmMMeta?.alias || [],
    redirect: indexbxBsV8hqmMMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxBsV8hqmMMeta?.name ?? "inserate___en",
    path: indexbxBsV8hqmMMeta?.path ?? "/en/inserate",
    meta: indexbxBsV8hqmMMeta || {},
    alias: indexbxBsV8hqmMMeta?.alias || [],
    redirect: indexbxBsV8hqmMMeta?.redirect,
    component: () => import("/app/.app/pages/inserate/index.vue").then(m => m.default || m)
  },
  {
    name: indexpjTOQQUvuqMeta?.name ?? "job-presets-id___de",
    path: indexpjTOQQUvuqMeta?.path ?? "/job-presets/:id()",
    meta: indexpjTOQQUvuqMeta || {},
    alias: indexpjTOQQUvuqMeta?.alias || [],
    redirect: indexpjTOQQUvuqMeta?.redirect,
    component: () => import("/app/.app/pages/job-presets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpjTOQQUvuqMeta?.name ?? "job-presets-id___en",
    path: indexpjTOQQUvuqMeta?.path ?? "/en/job-presets/:id()",
    meta: indexpjTOQQUvuqMeta || {},
    alias: indexpjTOQQUvuqMeta?.alias || [],
    redirect: indexpjTOQQUvuqMeta?.redirect,
    component: () => import("/app/.app/pages/job-presets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJ7Yt2kxg2Meta?.name ?? "job-presets-add___de",
    path: indexZJ7Yt2kxg2Meta?.path ?? "/job-presets/add",
    meta: indexZJ7Yt2kxg2Meta || {},
    alias: indexZJ7Yt2kxg2Meta?.alias || [],
    redirect: indexZJ7Yt2kxg2Meta?.redirect,
    component: () => import("/app/.app/pages/job-presets/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexZJ7Yt2kxg2Meta?.name ?? "job-presets-add___en",
    path: indexZJ7Yt2kxg2Meta?.path ?? "/en/job-presets/add",
    meta: indexZJ7Yt2kxg2Meta || {},
    alias: indexZJ7Yt2kxg2Meta?.alias || [],
    redirect: indexZJ7Yt2kxg2Meta?.redirect,
    component: () => import("/app/.app/pages/job-presets/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTg0g3ZNjeoMeta?.name ?? "job-presets-deactivated___de",
    path: indexTg0g3ZNjeoMeta?.path ?? "/job-presets/deactivated",
    meta: indexTg0g3ZNjeoMeta || {},
    alias: indexTg0g3ZNjeoMeta?.alias || [],
    redirect: indexTg0g3ZNjeoMeta?.redirect,
    component: () => import("/app/.app/pages/job-presets/deactivated/index.vue").then(m => m.default || m)
  },
  {
    name: indexTg0g3ZNjeoMeta?.name ?? "job-presets-deactivated___en",
    path: indexTg0g3ZNjeoMeta?.path ?? "/en/job-presets/deactivated",
    meta: indexTg0g3ZNjeoMeta || {},
    alias: indexTg0g3ZNjeoMeta?.alias || [],
    redirect: indexTg0g3ZNjeoMeta?.redirect,
    component: () => import("/app/.app/pages/job-presets/deactivated/index.vue").then(m => m.default || m)
  },
  {
    name: index8vw76RZCAZMeta?.name ?? "job-presets___de",
    path: index8vw76RZCAZMeta?.path ?? "/job-presets",
    meta: index8vw76RZCAZMeta || {},
    alias: index8vw76RZCAZMeta?.alias || [],
    redirect: index8vw76RZCAZMeta?.redirect,
    component: () => import("/app/.app/pages/job-presets/index.vue").then(m => m.default || m)
  },
  {
    name: index8vw76RZCAZMeta?.name ?? "job-presets___en",
    path: index8vw76RZCAZMeta?.path ?? "/en/job-presets",
    meta: index8vw76RZCAZMeta || {},
    alias: index8vw76RZCAZMeta?.alias || [],
    redirect: index8vw76RZCAZMeta?.redirect,
    component: () => import("/app/.app/pages/job-presets/index.vue").then(m => m.default || m)
  },
  {
    name: logincIYgecPLM8Meta?.name ?? "login___de",
    path: logincIYgecPLM8Meta?.path ?? "/login",
    meta: logincIYgecPLM8Meta || {},
    alias: logincIYgecPLM8Meta?.alias || [],
    redirect: logincIYgecPLM8Meta?.redirect,
    component: () => import("/app/.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logincIYgecPLM8Meta?.name ?? "login___en",
    path: logincIYgecPLM8Meta?.path ?? "/en/login",
    meta: logincIYgecPLM8Meta || {},
    alias: logincIYgecPLM8Meta?.alias || [],
    redirect: logincIYgecPLM8Meta?.redirect,
    component: () => import("/app/.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: platformswbhBFWbRIiMeta?.name ?? "platforms___de",
    path: platformswbhBFWbRIiMeta?.path ?? "/platforms",
    meta: platformswbhBFWbRIiMeta || {},
    alias: platformswbhBFWbRIiMeta?.alias || [],
    redirect: platformswbhBFWbRIiMeta?.redirect,
    component: () => import("/app/.app/pages/platforms.vue").then(m => m.default || m)
  },
  {
    name: platformswbhBFWbRIiMeta?.name ?? "platforms___en",
    path: platformswbhBFWbRIiMeta?.path ?? "/en/platforms",
    meta: platformswbhBFWbRIiMeta || {},
    alias: platformswbhBFWbRIiMeta?.alias || [],
    redirect: platformswbhBFWbRIiMeta?.redirect,
    component: () => import("/app/.app/pages/platforms.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordjE4cK81Pn5Meta?.name ?? "profile-change-password___de",
    path: change_45passwordjE4cK81Pn5Meta?.path ?? "/profile/change-password",
    meta: change_45passwordjE4cK81Pn5Meta || {},
    alias: change_45passwordjE4cK81Pn5Meta?.alias || [],
    redirect: change_45passwordjE4cK81Pn5Meta?.redirect,
    component: () => import("/app/.app/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordjE4cK81Pn5Meta?.name ?? "profile-change-password___en",
    path: change_45passwordjE4cK81Pn5Meta?.path ?? "/en/profile/change-password",
    meta: change_45passwordjE4cK81Pn5Meta || {},
    alias: change_45passwordjE4cK81Pn5Meta?.alias || [],
    redirect: change_45passwordjE4cK81Pn5Meta?.redirect,
    component: () => import("/app/.app/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexjlsJkxJZqCMeta?.name ?? "profile___de",
    path: indexjlsJkxJZqCMeta?.path ?? "/profile",
    meta: indexjlsJkxJZqCMeta || {},
    alias: indexjlsJkxJZqCMeta?.alias || [],
    redirect: indexjlsJkxJZqCMeta?.redirect,
    component: () => import("/app/.app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexjlsJkxJZqCMeta?.name ?? "profile___en",
    path: indexjlsJkxJZqCMeta?.path ?? "/en/profile",
    meta: indexjlsJkxJZqCMeta || {},
    alias: indexjlsJkxJZqCMeta?.alias || [],
    redirect: indexjlsJkxJZqCMeta?.redirect,
    component: () => import("/app/.app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: statisticsktP0BtS4SDMeta?.name ?? "statistics___de",
    path: statisticsktP0BtS4SDMeta?.path ?? "/statistics",
    meta: statisticsktP0BtS4SDMeta || {},
    alias: statisticsktP0BtS4SDMeta?.alias || [],
    redirect: statisticsktP0BtS4SDMeta?.redirect,
    component: () => import("/app/.app/pages/statistics.vue").then(m => m.default || m)
  },
  {
    name: statisticsktP0BtS4SDMeta?.name ?? "statistics___en",
    path: statisticsktP0BtS4SDMeta?.path ?? "/en/statistics",
    meta: statisticsktP0BtS4SDMeta || {},
    alias: statisticsktP0BtS4SDMeta?.alias || [],
    redirect: statisticsktP0BtS4SDMeta?.redirect,
    component: () => import("/app/.app/pages/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91id_93O6ATLKfC8UMeta?.name ?? "templates-edit-id___de",
    path: _91id_93O6ATLKfC8UMeta?.path ?? "/templates/edit/:id()",
    meta: _91id_93O6ATLKfC8UMeta || {},
    alias: _91id_93O6ATLKfC8UMeta?.alias || [],
    redirect: _91id_93O6ATLKfC8UMeta?.redirect,
    component: () => import("/app/.app/pages/templates/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93O6ATLKfC8UMeta?.name ?? "templates-edit-id___en",
    path: _91id_93O6ATLKfC8UMeta?.path ?? "/en/templates/edit/:id()",
    meta: _91id_93O6ATLKfC8UMeta || {},
    alias: _91id_93O6ATLKfC8UMeta?.alias || [],
    redirect: _91id_93O6ATLKfC8UMeta?.redirect,
    component: () => import("/app/.app/pages/templates/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWjD8yLleCAMeta?.name ?? "templates___de",
    path: indexWjD8yLleCAMeta?.path ?? "/templates",
    meta: indexWjD8yLleCAMeta || {},
    alias: indexWjD8yLleCAMeta?.alias || [],
    redirect: indexWjD8yLleCAMeta?.redirect,
    component: () => import("/app/.app/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexWjD8yLleCAMeta?.name ?? "templates___en",
    path: indexWjD8yLleCAMeta?.path ?? "/en/templates",
    meta: indexWjD8yLleCAMeta || {},
    alias: indexWjD8yLleCAMeta?.alias || [],
    redirect: indexWjD8yLleCAMeta?.redirect,
    component: () => import("/app/.app/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Px7AHBhIhFMeta?.name ?? "users-id___de",
    path: _91id_93Px7AHBhIhFMeta?.path ?? "/users/:id()",
    meta: _91id_93Px7AHBhIhFMeta || {},
    alias: _91id_93Px7AHBhIhFMeta?.alias || [],
    redirect: _91id_93Px7AHBhIhFMeta?.redirect,
    component: () => import("/app/.app/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Px7AHBhIhFMeta?.name ?? "users-id___en",
    path: _91id_93Px7AHBhIhFMeta?.path ?? "/en/users/:id()",
    meta: _91id_93Px7AHBhIhFMeta || {},
    alias: _91id_93Px7AHBhIhFMeta?.alias || [],
    redirect: _91id_93Px7AHBhIhFMeta?.redirect,
    component: () => import("/app/.app/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index7p2oZ4vBkVMeta?.name ?? "users___de",
    path: index7p2oZ4vBkVMeta?.path ?? "/users",
    meta: index7p2oZ4vBkVMeta || {},
    alias: index7p2oZ4vBkVMeta?.alias || [],
    redirect: index7p2oZ4vBkVMeta?.redirect,
    component: () => import("/app/.app/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: index7p2oZ4vBkVMeta?.name ?? "users___en",
    path: index7p2oZ4vBkVMeta?.path ?? "/en/users",
    meta: index7p2oZ4vBkVMeta || {},
    alias: index7p2oZ4vBkVMeta?.alias || [],
    redirect: index7p2oZ4vBkVMeta?.redirect,
    component: () => import("/app/.app/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: authkpJ2osrxIFMeta?.name ?? "auth-middleware___de",
    path: authkpJ2osrxIFMeta?.path ?? "/:pathMatch(.*)*",
    meta: authkpJ2osrxIFMeta || {},
    alias: authkpJ2osrxIFMeta?.alias || [],
    redirect: authkpJ2osrxIFMeta?.redirect,
    component: () => import("/app/.app/modules/auth/middleware/auth.ts").then(m => m.default || m)
  },
  {
    name: authkpJ2osrxIFMeta?.name ?? "auth-middleware___en",
    path: authkpJ2osrxIFMeta?.path ?? "/en/:pathMatch(.*)*",
    meta: authkpJ2osrxIFMeta || {},
    alias: authkpJ2osrxIFMeta?.alias || [],
    redirect: authkpJ2osrxIFMeta?.redirect,
    component: () => import("/app/.app/modules/auth/middleware/auth.ts").then(m => m.default || m)
  }
]
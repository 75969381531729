import { navigateTo, useRuntimeConfig } from '#app'
import { defineStore } from 'pinia'
import { fetchTeampool } from '~/modules/auth/plugins/fetchTeampool'
import type { User } from '~/types'

interface AuthState {
    authUser: User | null
    accessToken: string | null
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        authUser: null,
        accessToken: process.client ? localStorage.getItem('accessToken') : null,
    }),
    actions: {
        async login(credentials: { username: string; password: string }) {
            const runtimeConfig = useRuntimeConfig()
            try {
                const payload = new FormData()
                payload.append('username', credentials.username)
                payload.append('password', credentials.password)

                const data = await $fetch(`${runtimeConfig.public.apiBase}/token`, {
                    method: 'POST',
                    body: payload,
                    credentials: 'include',
                })

                this.accessToken = data.access_token
                localStorage.setItem('accessToken', this.accessToken)

                // Fetch the user data
                await this.fetchUser()

                navigateTo('/')
            } catch (error) {
                console.error('Login error:', error)
                throw new Error(error)
            }
        },
        async fetchUser() {
            const config = useRuntimeConfig()

            try {
                const data = await fetchTeampool(`/user/whoami`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                })
                this.setUser(data.data)
            } catch (error) {
                console.error('Fetch user error:', error)
                await this.logout()
            }
        },

        async logout() {
            const runtimeConfig = useRuntimeConfig()
            try {
                await $fetch(`${runtimeConfig.public.apiBase}/logout`, {
                    method: 'DELETE',
                    credentials: 'include',
                })
            } catch (error) {
                console.error('Refresh error:', error)
            }
            this.authUser = null
            this.accessToken = null
            if (process.client) {
                localStorage.removeItem('accessToken')
            }
            navigateTo('/login')
        },
        async refresh() {
            const runtimeConfig = useRuntimeConfig()

            try {
                const data = await $fetch(`${runtimeConfig.public.apiBase}/refresh`, {
                    method: 'GET',
                    credentials: 'include',
                })
                this.accessToken = data.access_token

                if (process.client) {
                    localStorage.setItem('accessToken', this.accessToken)
                }
            } catch (error) {
                console.error('Refresh error:', error)
                await this.logout()
            }
        },
        isAuthenticated() {
            return !!this.authUser
        },
        getToken() {
            return this.accessToken
        },
        setUser(user) {
            this.authUser = user
        },
    },
})

/**
 * This file is used to configure the app
 *
 * If you have the "Cannot find name 'defineAppConfig'.ts(2304)" error
 * update the root tsconfig.json file to include the following:
 *
 *  "extends": "./.app/.nuxt/tsconfig.json"
 *
 */

export default defineAppConfig({
    nuxtIcon: {},
    tairo: {
        title: 'Teampool Portal',
        error: {
            logo: {
                component: 'TeampoolLogo',
                props: {
                    class: 'text-primary-500 mx-auto h-40 p-6',
                },
            },
        },
        panels: [],
        collapse: {
            circularMenu: {
                enabled: false,
                tools: [],
            },
            toolbar: {
                enabled: true,
                showTitle: true,
                showNavBurger: true,
                tools: [
                    {
                        component: 'TeampoolJobContextDropdown',
                    },
                    {
                        component: 'ThemeToggle',
                    },
                ],
            },
            navigation: {
                enabled: true,
                header: {
                    component: 'TeampoolCollapseNavigationHeader',
                },
                logo: {
                    component: 'TeampoolLogo',
                    props: {
                        class: 'text-primary-500 mx-auto h-40 p-6',
                    },
                },
                footer: {
                    component: 'TeampoolCollapseNavigationFooter',
                },
                items: [
                    /*          {
                                name: 'Statistik',
                                icon: { name: 'ph:chart-donut-fill', class: 'w-5 h-5' },
                                to: '/statistics',
                              },*/
                ],
            },
        },
    },
    toaster: {
        duration: 3000,
        dismissible: false,
        theme: {
            maxToasts: 5,
            containerClass: [
                'fixed',
                'inset-0',
                'pointer-events-none',
                'p-4',
                'flex',
                'flex-col-reverse', // bottom
                'overflow-hidden',
                'z-[200]',
                'items-end', // end
                'gap-2',
                'space-y-3',
            ],
            wrapperClass: ['pointer-events-auto', 'focus:outline-none', 'rounded', 'outline-slate-300', 'outline-offset-2', 'focus:outline', 'focus:outline-2', 'focus-within:outline', 'focus-within:outline-2'],
            transition: {
                enterActiveClass: 'transition duration-300 ease-out',
                enterFromClass: 'transform translate-y-full opacity-0',
                enterToClass: 'transform translate-y-0 opacity-100',
                leaveActiveClass: 'transition duration-200 ease-in',
                leaveFromClass: 'transform translate-y-0 opacity-100',
                leaveToClass: 'transform translate-y-full opacity-0',
            },
        },
    },
    nui: {
        BaseButton: {
            rounded: 'lg',
        },
        BaseCard: {
            rounded: 'lg',
        },
        BaseInput: {
            rounded: 'lg',
        },
        BaseSelect: {
            rounded: 'lg',
        },
        BaseAutocomplete: {
            rounded: 'lg',
            i18n: { default: 'Laden ...', empty: 'Keine Ergebnisse gefunden.', pending: 'Laden ...' },
        },
        BaseInputNumber: {
            rounded: 'lg',
        },
        BaseTextarea: {
            rounded: 'lg',
        },
        BaseinputFile: {
            rounded: 'lg',
        },
    },
})

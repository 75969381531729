import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { useAuthStore } from '~/modules/auth/stores/auth'

const fetchTeampool = async (url: string, options: any = {}) => {
    const config = useRuntimeConfig()
    const authStore = useAuthStore()

    const token = authStore.getToken()

    let retriedResponse = null

    const handleResponseError = async ({ request, response, options }) => {
        if (response?.status === 401) {
            try {
                const newOptions = JSON.parse(JSON.stringify(options))
                delete newOptions.onResponseError

                await authStore.refresh()

                newOptions.headers = newOptions.headers || {}
                newOptions.headers.Authorization = `Bearer ${authStore.getToken()}`

                retriedResponse = await $fetch(request, newOptions)

                return retriedResponse
            } catch (error) {
                console.error('Token refresh error:', error)
                authStore.logout()
                throw error
            }
        }

        throw response
    }

    if (token) {
        options.headers = options.headers || {}
        options.headers.Authorization = `Bearer ${token}`
    }

    options.onResponseError = handleResponseError

    try {
        const data = await $fetch(config.public.apiBase + url, options)
        return data
    } catch (error) {
        console.error('error but retriedResponse', retriedResponse)
        if (retriedResponse) {
            return retriedResponse
        } else {
            console.error('Final request error:', error)
            throw error
        }
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.provide('fetchTeampool', fetchTeampool)

    nuxtApp.vueApp.mixin({
        beforeCreate() {
            this.$fetchTeampool = fetchTeampool
        },
    })
})

export { fetchTeampool }

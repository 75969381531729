import revive_payload_client_qFeEgVkH4K from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.2_sass@1.75.0_t_iqrgvlndu7w6ifm3qbqyubkvma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bTJZ3D6OVj from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.2_sass@1.75.0_t_iqrgvlndu7w6ifm3qbqyubkvma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vVbgQ3mjsQ from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.2_sass@1.75.0_t_iqrgvlndu7w6ifm3qbqyubkvma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nrA1LiioO4 from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.2_sass@1.75.0_t_iqrgvlndu7w6ifm3qbqyubkvma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_jw4M5KC5uK from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.2_sass@1.75.0_t_iqrgvlndu7w6ifm3qbqyubkvma/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Auk4IrOzBI from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.app/.nuxt/components.plugin.mjs";
import prefetch_client_ZM0ztE8qS6 from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.2_sass@1.75.0_t_iqrgvlndu7w6ifm3qbqyubkvma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_exNVq2O2Nn from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_8ZQh7hY0br from "/app/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_vue@3.4.21/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import i18n_Nig0BT66Mx from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.14.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_nezRuybcMi from "/app/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.2_sass@1.75.0_t_iqrgvlndu7w6ifm3qbqyubkvma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_yBPzJQtdia from "/app/layers/tairo/plugins/directives.ts";
import fetchTeampool_f8B9jKNZS9 from "/app/.app/modules/auth/plugins/fetchTeampool.ts";
import dayjs_pGiXRjcsJO from "/app/.app/plugins/dayjs.ts";
import sentry_3AyO8nEfhE from "/app/.app/plugins/sentry.ts";
export default [
  revive_payload_client_qFeEgVkH4K,
  unhead_bTJZ3D6OVj,
  router_vVbgQ3mjsQ,
  payload_client_nrA1LiioO4,
  check_outdated_build_client_jw4M5KC5uK,
  plugin_vue3_Auk4IrOzBI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZM0ztE8qS6,
  plugin_client_exNVq2O2Nn,
  plugin_8ZQh7hY0br,
  i18n_Nig0BT66Mx,
  chunk_reload_client_nezRuybcMi,
  directives_yBPzJQtdia,
  fetchTeampool_f8B9jKNZS9,
  dayjs_pGiXRjcsJO,
  sentry_3AyO8nEfhE
]
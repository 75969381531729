<script lang="ts" setup>
const { t } = useI18n()

const props = defineProps<{
    error: any
}>()

const title = computed(() => {
    if (props.error?.statusCode === 401) {
        return t('error.401.title')
    }

    if (props.error?.statusCode === 404) {
        return t('error.404.title')
    }

    if (props.error?.statusCode === 403) {
        return t('error.403.title')
    }

    return t('error.unknown.title')
})

const description = computed(() => {
    if (props.error?.statusCode === 401) {
        return t('error.401.description')
    }

    if (props.error?.statusCode === 404) {
        return t('error.404.description')
    }

    if (props.error?.statusCode === 403) {
        return t('error.403.description')
    }

    return t('error.unknown.description')
})

const app = useAppConfig()
const handleError = () => clearError({ redirect: '/' })
const retry = () => clearError()

// Show/hide demo stack trace
const showStackTrace = ref(true)
</script>

<template>
    <div class="p-3 pb-16">
        <BasePlaceholderPage :subtitle="description" :title="title" class="relative !items-end" image-size="md">
            <template #image>
                

                <img alt="error" class="w-96 h-80 max-w-[80vw] object-contain mx-auto"
                     src="/teampool/error/teampool-error.svg" />
            </template>

            <div class="mt-4">
                <div
                    class="text-muted-400/20 dark:text-muted-400/10 absolute inset-x-0 top-1/3 -translate-y-1/2 text-[11rem] font-bold sm:text-[20rem]">
                    <span>{{ props.error?.statusCode }}</span>
                </div>
                <div class="mx-auto flex w-full max-w-xs items-center justify-center gap-2">
                    <BaseButton class="mx-auto !h-12 w-full max-w-[160px] items-center gap-2" rounded="lg"
                                @click="handleError">
                        <Icon name="feather:arrow-left" />
                        zur Startseite
                    </BaseButton>
                    
                </div>
                
            </div>
        </BasePlaceholderPage>

        
    </div>
</template>

<style>
.stack {
    @apply text-sm;
}

.stack.internal {
    @apply opacity-100 ps-4 text-xs;
}
</style>

import dayjs from 'dayjs'
import DE from 'dayjs/locale/de-at'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default defineNuxtPlugin((nuxtApp) => {
    dayjs.extend(localizedFormat)
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale(DE)
    dayjs.tz.setDefault(dayjs.tz.guess())
    nuxtApp.provide('dayjs', dayjs)
})

declare module '#app' {
    interface NuxtApp {
        $dayjs: typeof dayjs
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $dayjs: typeof dayjs
    }
}
